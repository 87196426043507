<div class="mkg-frontpanel">
  <h2 class="mb-0">Acesso rápido</h2>
  <mat-card-subtitle>Arraste e organize os menus conforme desejado</mat-card-subtitle>

  <div class="d-flex mt-4">
    <div class="container sticky">
      <h3 class="txt-center">Menus escolhidos</h3>
      <div cdkDropList
           #todoList="cdkDropList"
           [cdkDropListData]="quickAccess"
           [cdkDropListConnectedTo]="[doneList]"
           class="list"
           (cdkDropListDropped)="drop($event)">
        <div class="box"
             *ngFor="let item of quickAccess; index as index"
             (dblclick)="onDoubleClick(quickAccess, availableMenus, index)"
             cdkDrag>
          <mat-icon>star_border</mat-icon>
          {{ ('MENU.'+item) | translate }}
        </div>
        <p *ngIf="!quickAccess.length"
           class="txt-center mt-3">Arraste pra cá ou dê um duplo clique nos itens</p>
      </div>

      <div class="d-flex mt-3 justify-content-center">
        <button mat-raised-button
                (singleClick)="save()"
                color="primary">Salvar</button>
      </div>
    </div>

    <div class="container">
      <h3 class="txt-center">Menus disponíveis</h3>

      <div cdkDropList
           #doneList="cdkDropList"
           [cdkDropListData]="availableMenus"
           [cdkDropListConnectedTo]="[todoList]"
           class="list"
           (cdkDropListDropped)="drop($event)">
        <div class="box"
             *ngFor="let item of availableMenus; index as index"
             (dblclick)="onDoubleClick(availableMenus, quickAccess, index)"
             cdkDrag>
          <mat-icon>menu</mat-icon>
          {{ ('MENU.'+item) | translate }}
        </div>
      </div>

    </div>
  </div>

</div>
