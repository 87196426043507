import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { MkgUser, User } from '../../../interface/user';
import { DataService } from '../../../service/data.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { UserService } from '../../../service/user.service';
import { roles } from '../../../shared/lists/roles';

const CONSULTER_MENUS = [
  'home',
  'budget',
  'rejected',
  'opened',
  'closed',
  'ended',
  'clients',
  'vehicles',
  'parts',
  'maintenance',
  'labors',
  'orders',
  'suppliers',
  'invoices',
  'xml',
  'stock'
];

const MANAGER_MENUS = [
  'cash-title',
  'receipt',
  'banks',
  'conciliation',
  'detached',
  'users',
  'operations',
  'commissions',
  'dashboard',
  'sales',
  'user-sales',
  'profits',
  'profits-by-os',
  'dre',
  'os-vehicle-history',
  'vehicle-history',
  'os-history',
  'application-list',
  'bank-list',
  'carte-list',
  'additional-item',
  'parcels',
  'checklist',
  'checklist-models',
  'payment-condition-list',
  'custom-state-list',
  'custom-state-budget-list',
  'cashier-event-list',
  'group-list',
  'brand-list',
  'survey-list',
  'subgroup-list',
  'expense-list',
  'ro-types',
  'labor-types-list',
  'title-type-list',
  'control-messages'
]

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent implements OnInit {

  quickAccess: string[] = [];
  availableMenus: string[] = [];


  constructor(
    private _layout: LayoutService,
    private _dataService: DataService,
    private _userService: UserService,
    private _snackbar: SnackbarService
  ) {
    this._dataService.userRole$
      .pipe(
        filter(rol => !!rol),
        first()
      )
      .subscribe(role => {
        switch (role) {
          case roles.mechanic.id:
            console.warn('acesso rápido não disponível para usuario com nível de acesso "Mecânico"')
            break;
          case roles.consultant.id:
            this.availableMenus = CONSULTER_MENUS;
            break;
          case roles.manager.id:
          case roles.admin.id:
          default:
            this.availableMenus = CONSULTER_MENUS.concat(MANAGER_MENUS);
            break;
        }

        if (Array.isArray(this._dataService.user.quickAccess) && this._dataService.user.quickAccess.length) {
          for (const quick of this._dataService.user.quickAccess) {
            const index = this.availableMenus.findIndex(m => m === quick);
            if (index !== -1) {
              transferArrayItem(this.availableMenus, this.quickAccess, index, this.quickAccess.length);
            }
          }
        }
      });
  }

  ngOnInit() {
    this._layout.component = 'configure';
    this._layout.loader = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onDoubleClick(currentArray: string[], targetArray: string[], currentIndex: number) {
    transferArrayItem(currentArray, targetArray, currentIndex, targetArray.length)
  }

  public async save() {
    try {
      this._layout.loader = true;
      this._dataService.user.quickAccess = this.quickAccess;
      await this._userService.update(this._dataService.user, this._dataService.company);
      this._dataService.user$.next(this._dataService.user);
      this._snackbar.success('USER.UPDATED', {name: this._dataService.user.firstName});
    } catch (error) {
      console.error(error);
      this._snackbar.error('USER.NOT_UPDATED', {name: this._dataService.user.firstName}, error);
    } finally {
      this._layout.loader = false;
    }

  }

}







